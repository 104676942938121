import React, { Component, Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  Form,
  Checkbox, 
  Grid,
  Divider,
  Rating,
  Icon,
  Message,
  Statistic,
  Sidebar,
  Label,
  Dropdown,
  Placeholder,
  Dimmer,
  Loader,
  Table,
  Modal
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import conf from './../../assets/img/conf.jpg'
import ReadMoreAndLess from 'react-read-more-less';
//import brandLogo from './../../assets/img/brandlogo.png';
import './../../assets/css/main.css';
import company from './../../assets/img/companies.png';
import meeting from './../../assets/img/meetings.png';
import speaker from './../../assets/img/speaker.png';
import FilterHeader from './../components/FilterHeader.jsx';
import axios from 'axios';
import SingleConferencePage from './SingleConferencePage.jsx'
import LazyLoad from 'react-lazyload';
import html2canvas from 'html2canvas';
import TimeMe from  'timeme.js';
import ad from './../../assets/img/ad.png';
import download from './../../assets/img/download.png';
var DEFAULTPLAN='Annual';
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
var backup_conf;
var TimeOutSave;
var TimeOutRecord;
var common;
var timeDuration = 5000;
var selectType = "";
const ics = require('ics');
class SearchEvents extends Component{
  state = {industries:[],activeCol:"",calendarInfo: {}, undo:false,modalOpen:false,leftpanel:true,fetchingdata:true,conf_list:[0,1,2,3,4],totalconf:null,rightside:null,changedfilters:false,filters:{},sort:'date',user:{}}
  handleSidebarHide = () => {
    //console.log()
  if(this.state.leftpanel){
    TimeMe.startTimer("expanded-conf");
  }
  else{
    TimeMe.stopTimer("expanded-conf");
    var timespent = TimeMe.getTimeOnPageInSeconds("expanded-conf")
    //console.log(timespent,"time")
    TimeMe.resetAllRecordedPageTimes();
   var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
    axios.post('record',{identity:11,conference_id:this.state.rightside.id,time_spent:timespent,event_type:'Expanded Conference Card',plan_type:plan_name[0].name}).then(done => {

    }).catch(err => {
      console.log(err,"analytics error")
    })
  }
  this.setState({leftpanel:!this.state.leftpanel})}
  
  componentDidMount(){
    var user = JSON.parse(localStorage.user_details);
     this.setState({user:user,plan:user.plan});
     TimeMe.setIdleDurationInSeconds(60)
     axios.get('fetch_event_calendar_data').then(calendar => {
                calendar.data.map(y => {
                    if (!y.early_bird) {

                        y.borderColor = '#8d72ff'
                        y.color = '#eeeaff'
                        y.textColor = "#000000"
                    } else {
                        y.title = "EB " + y.title;
                        y.borderColor = '#e70000'
                        y.color = '#ffeaea'
                        y.textColor = "#000000"
                    }

                    y.allDay = true;
                })
                this.setState({ cal_events: calendar.data })
            }).catch(err => {
                console.log(err);
            })
  //this.fetchsearchlist();
  }
   componentDidUpdate(prevProps,prevState){

  // if(prevState.fetchingdata != this.state.fetchingdata){
  //   //console.log(prevState,this.state,"st")
  //   var tp = window.tp;
  //      //console.log(tp,"tp")
  //      tp.experience.execute();
  // }
 }
  componentWillUnmount(){
    if(TimeMe.getTimeOnPageInSeconds("expanded-conf")>0){
      var timespent = TimeMe.getTimeOnPageInSeconds("expanded-conf")
      var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
      axios.post('record',{identity:11,conference_id:this.state.rightside.id,time_spent:timespent,event_type:'Expanded Conference Card',plan_type:plan_name[0].name}).then(done => {

      }).catch(err => {
        console.log(err,"analytics error")
      })
      TimeMe.resetAllRecordedPageTimes();
      //console.log(timespent,"time")
    }
  }
  fetchsearchlist = (industry,location,date,search,range_type) => {
    //console.log('called 5')
    if(industry && industry.length==0){
      industry = null;
    }
    if(location && location.length==0)
    {
        location=null;
    }
    this.setState({fetchingdata:true})
      axios.post('conferences_list/'+this.state.sort,{industries:industry,location:location,date:date,search:search,range_type:range_type,plan:DEFAULTPLAN}).then(data => {
      if(DEFAULTPLAN=='Free'){
        data.data.conferences = data.data.conferences.splice(0,3);
      //  data.data.total =3;
      }
      else if(DEFAULTPLAN=='Month'){
        data.data.conferences = data.data.conferences.splice(0,10);
      }
      this.setState({conf_list:data.data.conferences,totalconf:data.data.total,fetchingdata:false,rightside:data.data.conferences[0]})
      if(data.data.industries){
        var industries = JSON.parse(data.data.industries);
        axios.get('fetch_industries').then(ind =>{
          if(ind.data.length>0){
            ind.data.map(obj => {
              obj.selected= false;
            })
            //this.setState({industries:ind.data});
            var ind_orignal = ind.data;
            //console.log(industries,"id")
            var id =[];
            industries.map(obj => {
              var id_find = ind_orignal.findIndex(obj1 => {
                return obj1.id == obj;
              })
              id.push(id_find)
              // console.log(obj,id.id,"id")
              // if(id){
              //  ind_orignal[id.id].selected=true;
              //  this.setState({industries:ind_orignal});
              // }
            })
            //console.log(id,"id")
            id.map(obj => {
              ind_orignal[obj].selected=true;
            })
            this.setState({industries:ind_orignal,changedfilters:true})
          }
        }).catch(err => {
          console.log(err);
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }

  openonright = (obj) => event => {
    if(obj.id){
       window.scroll({
            top: 0,
            behavior: 'smooth'
          });
      this.setState({rightside:obj})
    }
  }
changeFilter = (filters,date,location,search,range_type,type) => {
  var plan = JSON.parse(localStorage.getItem('user_details')).plan;
        this.setState({changedfilters:false,activeCol:""})
    //console.log(filters,"f")
    
      //this.setState({industries:filters,changedfilters:true})
    if(filters.length){
      var industries= filters.filter(x => {if(x.selected){return x.id}});
        industries= industries.map(x => x.id)
    }
    else{
      var industries = this.state.industries.map(i=>i.id);
    }
    // if(date){
    //  this.setState({date_last:date})
    // }
    
  //  console.log(industries,"i")
    if(search == ''){
      search = null;
    }
    this.fetchsearchlist(industries,location,date,search,range_type)
    this.setState({filters:{industry:industries,location,date,search,range_type:range_type}},() => {
    axios.post('save_events_filters',this.state.filters).then(saved => {

    }).catch(err => {
      console.log(err);
    })
    // if(type!='applied' && type!='reset'){
    //  localStorage.setItem('industries',JSON.stringify(industries));
    //  localStorage.setItem('filters',JSON.stringify(this.state.filters))
    //  //localStorage.setItem('search',search)
    // }
    if(localStorage.getItem('industries')){
      var locindustries = localStorage.getItem('industries');
      if(JSON.stringify(industries) != locindustries){
        localStorage.setItem('industries',JSON.stringify(industries))
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        axios.post('record',{identity:1,industry_id:industries,via:'Search Events',plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error")
            })
      }
    }
    else{
        localStorage.setItem('industries',JSON.stringify(industries))
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        axios.post('record',{identity:1,industry_id:industries,via:'Search Events',plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error")
            })
    }

    //console.log(localStorage.industries,JSON.parse(localStorage.filters),JSON.stringify(industries) != localStorage.industries,JSON.stringify(this.state.filters) != localStorage.filters,this.state.filters,"check")
      
    if(localStorage.getItem('filters')){
      var locfilters = localStorage.getItem('filters');
      if(JSON.stringify(this.state.filters) != locfilters){
        //console.log(this.state.filters,"f")
        localStorage.setItem('filters',JSON.stringify(this.state.filters));
        var send_filters = {...this.state.filters}
        send_filters.identity = 2;
        if(send_filters.date) {
          send_filters.range_type = null;
        }
        if(send_filters.range_type){
          send_filters.past_upcoming = send_filters.range_type;
        }
        if(send_filters.search){
          send_filters.text = send_filters.search;
        }
        if(send_filters.location){
          send_filters = {...send_filters, ...send_filters.location}
          if(send_filters.location.country)
          send_filters.location.country=send_filters.location.country.sort();
        }

        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        send_filters.plan_type = plan_name[0].name;
        send_filters.total_country=28;
        axios.post('record',send_filters).then(done => {
            }).catch(err => {
              console.log(err,"analytics error")
            })
      }
    }
    else{
      localStorage.setItem('filters',JSON.stringify(this.state.filters));
        var send_filters = {...this.state.filters}
        send_filters.identity = 2;
        if(send_filters.date) {
          send_filters.range_type = null;
        }
        if(send_filters.range_type){
          send_filters.past_upcoming = send_filters.range_type;
        }
        if(send_filters.search){
          send_filters.text = send_filters.search;
        }
        if(send_filters.location){
          send_filters = {...send_filters, ...send_filters.location}
        }
        
        //console.log(send_filters,"f")
        //send_filters
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        send_filters.plan_type=plan_name[0].name;
        send_filters.total_country=28;
        axios.post('record',send_filters).then(done => {
            }).catch(err => {
              console.log(err,"analytics error")
            })
    }
      
    // if(location && type=='applied'){
    //  if(JSON.stringify(location) != localStorage.location){
    //    localStorage.setItem('location',JSON.stringify(location))
    //    axios.post('record',{identity:2,state:location.state,country:location.country}).then(done => {
    //         }).catch(err => {
    //           console.log(err,"analytics error")
    //         })
    //  }
    // }
    // if(search && type=='applied'){
    //  if(search != localStorage.search){
    //    localStorage.setItem('search',search)
    //    axios.post('record',{identity:3,text:search}).then(done => {
    //         }).catch(err => {
    //           console.log(err,"analytics error")
    //         })
    //  }
    // }
    
    })
  }
  downloadCsv = (file_type,id,downloadLink) =>{
    // console.log(file_type,id,downloadLink,"values",DEFAULTPLAN,"myplan")
    // if(DEFAULTPLAN!='Free' && DEFAULTPLAN!='Free1' && DEFAULTPLAN!='Month') {''
    if(DEFAULTPLAN!='Free' && DEFAULTPLAN!='Month') {
          this.setState({downloading:true},()=>{
                var filters = {...this.state.filters};
                  filters.conferences=[...this.state.conf_list];
                   var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }

         if(file_type==="Search Events List") {
          if (DEFAULTPLAN!='Free' && DEFAULTPLAN!='Month' && DEFAULTPLAN!='Free1'){
              axios({method:'POST',url:'download_events_list',data:filters,responseType:'blob'}).then(data => {
              const url = window.URL.createObjectURL(new Blob([data.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'EA_conference_list.xlsx'); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.setState({downloading:false},()=>{
                    axios.post('record',{identity:5,file_type,file_format:'csv',conference_id:id,via:'Search-Events',plan_type:plan_name[0].name}).then(done => {

                }).catch(err => {
                  console.log(err,"analytics error");
                })
              })
               var conf = filters.conferences.map(x =>  x.id);
              

      }).catch(err => {
        console.log(err);
      })
         }
     }
         else if(file_type === 'Brochure'){
          this.setState({downloading:false})
          // console.log(id,"id",downloadLink,"downloadLink",downloadLink.split('.'));
          if(downloadLink.indexOf("/c_docs/")==-1){
            file_type = "Single Conference Detail";
            // console.log("SCF")
          }
          var fileformat = downloadLink.split('.')[downloadLink.split('.').length-1];
          axios.post('record',{identity:5,file_type,file_format:fileformat,conference_id:id,via:'Search-Events',plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
              console.log(err,"analytics error");
            })
         }
         else{
          this.setState({downloading:false})
           axios.post('record',{identity:5,file_type,file_format:'csv',conference_id:id,via:'Search-Events',plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
              console.log(err,"analytics error");
            })
         }
          })

    }
      
  }
  downloadPdf = () => {
    var that = this;
    var rightside = {...this.state.rightside};
    this.setState({downloading:true})
    rightside.downloading=true;
    this.setState({rightside:rightside},() => {
      setTimeout(function(){
        const input = document.getElementById('print-pdf');
        var width = input.offsetWidth;
            var height = input.offsetHeight;
            //input.style.fontFeatureSettings ='"liga" 0'
      //console.log(input,"i")
        html2canvas(input,{  logging:true,useCORS:true,dpi:300,scale:2.0,
                          })
        .then((canvas) => {
           // var ctx = canvas.getContext('2d');
          // ctx.webkitImageSmoothingEnabled = true;
          // ctx.mozImageSmoothingEnabled = true;
          // ctx.imageSmoothingEnabled = true;
          const imgData = canvas.toDataURL('image/png');
          //console.log(imgData,"img")
          //console.log(canvas.width,canvas.height,width,height,"ii")
          axios({method:'POST',url:'download_individual_event',data:{width:canvas.width,height:canvas.height,img:imgData},responseType:'blob'}).then(data => {
            var name = rightside.name+'.pdf'
          const link = document.createElement('a');
           // console.log(data.data,"d")
            const url = window.URL.createObjectURL(new Blob([data.data]));
            link.href = url;
            link.setAttribute('download', name); 
            link.setAttribute("target", "_blank"); //or any other extension
            document.body.appendChild(link);
            //console.log(link,"link")
            that.setState({downloading:false})
            link.click();
           var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
            axios.post('record',{identity:5,file_type:'Single Conference Detail',file_format:'pdf',conference_id:[rightside.id],via:'Search-Events',plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
              console.log(err,"analytics error");
            })
          }).catch(err => {
            console.log(err);
          })
            //const pdf = new jsPDF();
     //         var pdf = new jsPDF("p", "px", "a3");
     //         console.log(pdf,"pdf")
        //  //var width = pdf.internal.pageSize.getWidth();
        // //var height = pdf.internal.pageSize.getHeight();
      //       pdf.addImage(imgData, 'JPEG', 0, 0,width,height);
      //       // pdf.output('dataurlnewwindow');
      //       console.log(pdf,"pdf")
      //       pdf.save("download.pdf");
    //console.log(width,height,"5678")
    
  //    var pdf = new jsPDF('p', 'px', [870,1500]);

 //    for (var i = 0; i <= input.clientHeight/980; i++) {
 //        //! This is all just html2canvas stuff
 //        var srcImg  = canvas;
 //        var sX      = 0;
 //        var sY      = 1120*i; // start 980 pixels down for every new page
 //        var sWidth  = 778;
 //        var sHeight = 1120;
 //        var dX      = 0;
 //        var dY      = 0;
 //        var dWidth  = 778;
 //        var dHeight = 1120;

 //        var onePageCanvas = window.onePageCanvas = document.createElement("canvas");
 //        onePageCanvas.setAttribute('width', 870);
 //        onePageCanvas.setAttribute('height', 1500);
 //        var ctx = onePageCanvas.getContext('2d');
 //        // details on this usage of this function: 
 //        // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
 //        ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

 //        // document.body.appendChild(canvas);
 //        var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

 //        var width         = onePageCanvas.width;
 //        var height        = onePageCanvas.clientHeight;

 //        //! If we're on anything other than the first page,
 //        // add another page
 //        if (i > 0) {
 //            pdf.addPage(595, 842); //8.5" x 11" in pts (in*72)
 //        }
 //        //! now we declare that we're working on that page
 //        pdf.setPage(i+1);
 //        //! now we add content to that page!
 //        pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width*.72), (height*.71));


  //  }pdf.save('Test3.pdf');}
  //  )
  //  },4000)
    
  // }
    }
    )},2000)})}
  //componentWillUnmount(){
  //  axios.post('save_events_filters',this.state.filters).then(saved => {

  //  }).catch(err => {
  //    console.log(err);
  //  })
  // }
  undoChanges=(restore = true)=>{
    selectType = "";
    clearTimeout(TimeOutSave);
            clearTimeout(TimeOutRecord);
            clearTimeout(common);
      
        this.setState({conf_list:restore?backup_conf:this.state.conf_list,undo:false})
     
  }
  selectAllConf = () =>{
    selectType = "All";
    if(TimeOutSave){
      this.undoChanges();
    }
    var newConf  = this.state.conf_list.map(cf=>{
      return {...cf,starred:true};
    });
    this.handleEvent(newConf)(null,{rating:1})
  }
  resetAllConf = () =>{
    selectType = "Reset";
    if(TimeOutSave){
      this.undoChanges();
    }
    var newConf  = this.state.conf_list.map(cf=>{
      return {...cf,starred:false};
    });
    this.handleEvent(newConf)(null,{rating:0})
  }
  shouldRequestSend = (type)=>{
    if(type === selectType || selectType === "")
      return true;
    return false;
  }
  handleEvent = (ArrayId,type) => (event,data) => {
      if(TimeOutSave){
      clearTimeout(TimeOutSave);
        clearTimeout(TimeOutRecord);
          clearTimeout(common);
    }
    //event.stopPropagation();
        this.setState({undo:true},()=>{
              backup_conf = this.state.conf_list.map(cf=>{
      return { ...cf};
    });
    var conference = [...this.state.conf_list];
    var right_side = {...this.state.rightside}        
        if(data.rating==1){
                  if(ArrayId.length > 1) {
          conference.map(cf=>{
            cf.starred = true;
          })
        }
        else {
          conference.map(cf=>{
            if(cf.id === ArrayId[0].id) {
               cf.starred = true;             
          }
        }
        )}
        // TimeOutSave= setTimeout(()=>{
        //  if(this.state.undo ) {
        //      this.setState({undo:false},()=>{
        //          axios.post('save_event',{conference_id:ArrayId.map(cf=>cf.id)}).then(saved => {
      
        //          if(right_side.id == ArrayId.find(cf=>cf.id === right_side.id).id){
        //          if(data.rating==0){
        //            delete right_side.starred;
        //            delete right_side.changed;
        //          }
        //          else{
        //            right_side.starred=true;
        //            right_side.changed=true;
        //          }
        //          this.setState({rightside:right_side,undo:false})
        //        }
        //        }).catch(err => {
        //          console.log(err);
        //        })
        //          })
        //  }
        //  clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);
        // },timeDuration)

          axios.post('save_event',{conference_id:ArrayId.map(cf=>cf.id)}).then(saved => {
          if(right_side.id == ArrayId.find(cf=>cf.id === right_side.id).id){
          if(data.rating==0){
            delete right_side.starred;
            delete right_side.changed;
          }
          else{
            right_side.starred=true;
            right_side.changed=true;
          }
          this.setState({rightside:right_side,undo:false})
        }
        }).catch(err => {
          console.log(err);
        })

        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        // TimeOutRecord = setTimeout(()=>{
        //  if(this.state.undo) {
        //    this.setState({undo:false},()=>{
        //      axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'Search-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
        //    }).catch(err => {
        //      console.log(err,"analytics error");
        //    })
        //    })
        //  }
        //  clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);


        // },timeDuration)
              axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'Search-Events',star_type:type,action:'Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error");
            })
        }
        else{
                  if(ArrayId.length > 1) {
          conference.map(cf=>{
            cf.starred = false;
          })
        }
        else {
          conference.map(cf=>{
            if(cf.id === ArrayId[0].id) {
               cf.starred = false;              
          }
        }
        )}
        // TimeOutSave= setTimeout(()=>{
        //  if(this.state.undo ) {
        //      this.setState({undo:false},()=>{
        //          axios.post('delete_event',{conference_id:ArrayId.map(cf=>cf.id)}).then(saved => {
      
        //          if(right_side.id == ArrayId.find(cf=>cf.id === right_side.id).id){
        //          if(data.rating==0){
        //            delete right_side.starred;
        //            delete right_side.changed;
        //          }
        //          else{
        //            right_side.starred=true;
        //            right_side.changed=true;
        //          }
        //          this.setState({rightside:right_side,undo:false})
        //        }
        //        }).catch(err => {
        //          console.log(err);
        //        })
        //          })
        //  }
        //  clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);
        // },timeDuration)
          axios.post('delete_event',{conference_id:ArrayId.map(cf=>cf.id)}).then(saved => {
          if(right_side.id == ArrayId.find(cf=>cf.id === right_side.id).id){
          if(data.rating==0){
            delete right_side.starred;
            delete right_side.changed;
          }
          else{
            right_side.starred=true;
            right_side.changed=true;
          }
          this.setState({rightside:right_side,undo:false})
        }
        }).catch(err => {
          console.log(err);
        })

        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
        // TimeOutRecord = setTimeout(()=>{
        //  if(this.state.undo) {
        //    this.setState({undo:false},()=>{
        //      axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'Search-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
        //    }).catch(err => {
        //      console.log(err,"analytics error");
        //    })
        //    })
        //  }
        //  clearTimeout(TimeOutSave);
        //     clearTimeout(TimeOutRecord);
        //     clearTimeout(common);


        // },timeDuration)

              axios.post('record',{conference_id:ArrayId.map(cf=>cf.id),via:'Search-Events',star_type:type,action:'Un-Saved',identity:8,plan_type:plan_name[0].name}).then(done => {
            }).catch(err => {
              console.log(err,"analytics error");
            })

        }
      //console.log(right_side.id,obj.id,'oo')
      common=setTimeout(()=>{
          if(TimeOutSave) {
            this.undoChanges(false);
          }
        },timeDuration)
      
      this.setState({conf_list:conference});
        })
    
    
    
  }
  handleSort = (name) => event => {
    this.setState({sort:name},() => {
      this.fetchsearchlist(this.state.filters.industry,this.state.filters.location,this.state.filters.date,this.state.filters.search)
    })
  //  console.log(this.state,"s")
    
  }
  handleUpgradeClick=text=>{
    var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
    const data=JSON.stringify({identity:14,link:text,plan_type:plan_name[0].name});

    const config={headers:{"Content-Type":"application/json"}};
    axios.post("/record",data,config)
    .then(() =>console.log("success"))
    .catch((error) =>{console.log(error)});

  }
  openConfPopup = (id) => event => {
      TimeMe.startTimer("event-conf-popup");
      this.setState({modalOpen:id})
        
    }

 handleClose = () => {
  
      this.setState({ modalOpen: false })
    }
    backupData=()=>{
      this.setState({conf_list:[...backup_conf],undo:false})
    }
    checkLoading =( data, returnType = <Placeholder><Placeholder.Line/></Placeholder> )=> {
      const { fetchingdata } = this.state;
      if(fetchingdata)
        return (returnType);
      return data;
    }
        handleAnalytics = (obj) => event => {
        if(obj){
            var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
            axios.post('record',{identity:7,via:"Search-Events",email_to:obj.organiser,conference_id:obj.id,plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
                console.log(err,"analytics error");
            })
        }
    }
    icsDate = (today) => {
        // today= new Date(today)
        // today=moment(today).subtract(1, 'days');
        var todayDate = new Date(today)
        var dd = todayDate.getDate();
        var mm = (todayDate.getMonth() + 1); //January is 0!
        var yyyy = todayDate.getFullYear();
        var tmH = todayDate.getHours();
        var tmM = todayDate.getMinutes();
        // var tm = today.getTime();
        // var tmH = moment(today).tz('UTC').format('HH');
        //var tmM = moment(today).tz('UTC').format('mm');
        // today= yyyy+mm+dd+tm;
        var arrtime = [];
        arrtime.push(yyyy);
        arrtime.push(mm);
        arrtime.push(dd);
        arrtime.push(tmH);
        arrtime.push(tmM);
        // console.log("Date::",arrtime);
        return arrtime;
    };
    downloadModeChange = (obj, data) => {
        // console.log("Download Option",data);
        ics.createEvent({
            title: obj.name,
            start: this.icsDate(obj.start_date),
            end: this.icsDate(obj.end_date),
            description: obj.about,
            location: obj.venue + ', ' + obj.city
        }, (error, value) => {
            if (error) {
                console.log(error)
            }
            if (value) {
                // console.log(value)
                var uri = "data:text/calendar;charset=utf8," + escape(value);
                var downloadLink = document.createElement("a");
                downloadLink.href = uri;
                downloadLink.download = "event.ics";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);


            }

        })
        var plan_name =[{ name:DEFAULTPLAN}];
                    // if(DEFAULTPLAN=='Free'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id = process.env.REACT_APP_FREE_PLAN;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id){
                    //       return x; 
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Free1'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                    //    plan_name = resources.filter(x => {
                    //     if(x.rid==free_id1){
                    //       return x;
                    //     }
                    //   })

                    // }
                    // else if(DEFAULTPLAN=='Month'){
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==mtm_id){
                    //       return x;
                    //     }
                    //   })
                    // }
                    // else{
                    //   var resources = localStorage.getItem('resources');
                    //   resources = JSON.parse(resources);
                    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
                    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
                    //   plan_name = resources.filter(x => {
                    //     if(x.rid==ann_id || x.rid==etp_id){
                    //       return x;
                    //     }
                    //   })
                    // }
         axios.post('record',{identity:9,conference_id:obj.id,plan_type:plan_name[0].name}).then(done => {

            }).catch(err => {
                console.log(err,"analytics error")
            })
        axios.post('record',{identity:5,file_type:'Event Calendar',file_format:'ics',via:'Search-Events',conference_id:[obj.id],plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
            console.log(err,"analytics error")
        })
    }
      getPageName = () =>{
    var currentUrl = this.props.location.pathname;
    switch(currentUrl){
      case "/search-events":
           return "Search Events";
      case "/compare-peers":
           return "Compare Peers";
      case "/my-events":
           return "My Events";
      default : 
           return "Unknown Page";              
    }
  }
    sendAgendaData = (conference_id,link) =>{   
    var body = {
      conference_id,
      plan_type:this.calculatePlan()[0].name,
      identity:17,
      via:this.getPageName()
    };
    axios.post('record',body);
  }
    calculatePlan = () =>{
    var plan_name;
    var user = localStorage.getItem('user_details');
    if(user.plan=='Free'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var free_id = process.env.REACT_APP_FREE_PLAN;
                       plan_name = resources.filter(x => {
                        if(x.rid==free_id){
                          return x;
                        }
                      })

                    }
                    else if(DEFAULTPLAN=='Free1'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var free_id1 = process.env.REACT_APP_FREE_PLAN1;
                       plan_name = resources.filter(x => {
                        if(x.rid==free_id1){
                          return x;
                        }
                      })

                    }
                    else if(user.plan=='Month'){
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var mtm_id = process.env.REACT_APP_MTM_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==mtm_id){
                          return x;
                        }
                      })
                    }
                    else{
                      var resources = localStorage.getItem('resources');
                      resources = JSON.parse(resources);
                      var ann_id = process.env.REACT_APP_ANN_PLAN;
                      var etp_id = process.env.REACT_APP_ETP_PLAN;
                      plan_name = resources.filter(x => {
                        if(x.rid==ann_id || x.rid==etp_id){
                          return x;
                        }
                      })
                    }
        return plan_name;            
  }

    sort=(field,order)=>{
        
         this.state.conf_list.sort((obj1,obj2)=>{
         if(obj1[field] && obj2[field]) {
          var nameA=obj1[field].toLowerCase(), nameB=obj2[field].toLowerCase();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
         }
         else return 0;
      })
        
        if(order==="down"){
          this.state.conf_list=this.state.conf_list.reverse();
        }

      this.setState({activeCol:`${field} ${order}`})
    }
    sortNum=(field,order)=>{
      this.state.conf_list.sort((obj1,obj2)=>{
        var nameA=obj1[field], nameB=obj2[field];
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
      });
      if(order==="down"){
          this.state.conf_list=this.state.conf_list.reverse();
        }
        this.setState({activeCol:`${field} ${order}`})
    }
    getTooltip=obj=>{
      const { spo_count,exh_count,spe_count } = obj;
      var text='';
      if(spo_count) text=spo_count+' sponsor';
      if(exh_count) {
        if(text) text=text+', '
        text=text+exh_count+' exhibhitor';
    }
     if(spe_count) {
        if(text) text=text+' and '
        text=text+spe_count+' speaker';
    }
    if (text){
       text= text+(obj.companies>1?' companies':' company');
    }
    return text;
  }

// function quickSort(arr, leftPos, rightPos, arrLength) {
//   let initialLeftPos = leftPos;
//   let initialRightPos = rightPos;
//   let direction = true;
//   let pivot = rightPos;
//   while ((leftPos - rightPos) < 0) {
//     if (direction) {
//       if (arr[pivot] < arr[leftPos]) {
//         quickSort.swap(arr, pivot, leftPos);
//         pivot = leftPos;
//         rightPos--;
//         direction = !direction;
//       } else
//         leftPos++;
//     } else {
//       if (arr[pivot] <= arr[rightPos]) {
//         rightPos--;
//       } else {
//         quickSort.swap(arr, pivot, rightPos);
//         leftPos++;
//         pivot = rightPos;
//         direction = !direction;
//       }
//     }
//   }
//   if (pivot - 1 > initialLeftPos) {
//     quickSort(arr, initialLeftPos, pivot - 1, arrLength);
//   }
//   if (pivot + 1 < initialRightPos) {
//     quickSort(arr, pivot + 1, initialRightPos, arrLength);
//   }
// }
// quickSort.swap = (arr, el1, el2) => {
//   let swapedElem = arr[el1];
//   arr[el1] = arr[el2];
//   arr[el2] = swapedElem;
// }

// let length =this.state.conf_list.length
// quickSort(this.state.conf_list, 0, length - 1, length);
// this.setState({});
// }
    sortDate=(order)=>{
            this.state.conf_list.sort((obj1,obj2)=>{
        var nameA=new Date(obj1['start_date']).getTime(), nameB=new Date(obj2['start_date']).getTime();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0;
      });
      if(order==="down"){       
          this.state.conf_list=this.state.conf_list.reverse();
        }
        this.setState({activeCol:`date ${order}`})
    }
  render(){
    const {leftpanel, fetchingdata,activeCol} = this.state;
    var plan = DEFAULTPLAN;
    const arr = Array.from({length: 10}, () => Math.floor(Math.random() * 9));
    const arr1 = Array.from({length: 3}, () => Math.floor(Math.random() * 9));
    return(
      <Fragment>
      <style>
        {`
        .long.arrow.alternate{
              cursor: pointer;
            font-size: 18px;
            margin: 0;
            width: 11px;
        }
       .fl-right{
        display: inline-block;
        padding-left: 11px;
       }
       th.companies{
        padding-right:64px !important;

       }
       th.companies >div{
        margin-right:-50px;
        
       }
       .ui.table tr td {
    word-break: break-word;
    
      }
      .tooltip-child {
        position: absolute;
        top: 70%;
        right: 0;
        background: white;
        text-align: left;
        z-index: 55;
        transition: 0.2s;
        width: auto;
        display: none;
        padding: 5px;
        white-space: nowrap;
        box-shadow: -1px 5px 8px 3px #888888;
        color:#000;
        font-weight:bold;
      }

      table tr td:nth-child(8) {
        position: relative;
      }

      table tr td:nth-child(8):hover >div {
        display: block;
      }
      .inverted.download-menu{
        display:none !important;
      }
`
             }
      </style>
      <FilterHeader list={this.state.conf_list} filters={this.state.industries} action={this.changeFilter} changed={this.state.changedfilters} pdf={this.downloadPdf} csv={this.downloadCsv.bind(this,"Search Events List",this.state.conf_list.map(c=>c.id))}/>
      <Container fluid>
      <h4 style={{marginTop:"14px",paddingLeft:"11px"}}> { fetchingdata?<Fragment><Placeholder style={{width:"50px",float:"left",marginRight:"10px",marginTop:"4px"}}><Placeholder.Line/></Placeholder>Events Match Your Filter Criteria</Fragment>:`${this.state.conf_list.length} Events Match Your Filter Criteria`  }<Button disabled={fetchingdata || plan=='Free1' || !this.state.conf_list.length} onClick={this.selectAllConf} style = {{backgroundColor:"transparent",color:"#5290ff",paddingRight:"5px"}}>Select All </Button> <Button disabled={fetchingdata || plan=='Free1' || !this.state.conf_list.length} style = {{backgroundColor:"transparent",color:"#5290ff",paddingLeft:"0"}} onClick={this.resetAllConf}>Reset</Button> <Button disabled={fetchingdata} style = {{backgroundColor:"transparent",color:"#5290ff",paddingRight:"5px",float:"right"}} as="a" href={"mailto:support@thefr.com"+"?subject=Add new event to Event Analyzer"}>Add New Event</Button></h4>      
       {this.state.conf_list.length>0&& <Table unstackable className="normal-table" style={{display:"block",minWidth:"100%", marginTop:"14px",maxHeight:"800px",overflowY:"auto",marginBottom:"0px"}}>
                <Table.Header className="table-head">
                
                          <Table.Row style= {{minWidth:"100%"}}>                          
                            <Table.HeaderCell textAlign="center">Save</Table.HeaderCell>
                             <Table.HeaderCell >Email</Table.HeaderCell>
                            <Table.HeaderCell >Calendar</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Name<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sort.bind(this,"name","up")} style={{color:activeCol=="name up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sort.bind(this,"name","down")} style={{color:activeCol=="name down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={2}>Date<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sortDate.bind(this,"up")} style={{color:activeCol=="date up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sortDate.bind(this,"down")} style={{color:activeCol=="date down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            
                            <Table.HeaderCell className="companies" width={2}>City<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sort.bind(this,"city","up")} style={{color:activeCol=="city up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sort.bind(this,"city","down")} style={{color:activeCol=="city down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={2}>Industry</Table.HeaderCell>
                            <Table.HeaderCell width={2} textAlign="right" className="companies">Companies<div className="fl-right"><Icon name = "long arrow alternate up" onClick={this.sortNum.bind(this,"companies","up")} style={{color:activeCol=="companies up"?"#000000de":"#717171de"}}/><Icon name = "long arrow alternate down" onClick={this.sortNum.bind(this,"companies","down")} style={{color:activeCol=="companies down"?"#000000de":"#717171de"}}/></div></Table.HeaderCell>
                            <Table.HeaderCell width={1}>Agenda</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Info</Table.HeaderCell>
                          </Table.Row>
                </Table.Header>
                <Table.Body>
                {/*this.state.undo && <Table.Row style={{textAlign:"center"}}><Table.Cell colSpan={10} style = {{color:"#5290ff"}}><Icon style={{position:"relative",top:"4px"}}name = "redo" rotated="counterclockwise"/><Button style = {{color:"inherit",backgroundColor:"transparent",paddingLeft:"3px"}} onClick={this.undoChanges}>UNDO LAST CHANGES</Button></Table.Cell></Table.Row>*/}
                  { this.state.conf_list.map(obj=><Table.Row>
                            <Table.Cell textAlign="center">{this.checkLoading(<Rating style={{textAlign:"center"}}rating={obj.starred?1:0} onRate={this.handleEvent([obj])} size="huge" defaultRating={this.state.rating}/>,<Rating rating={1} size="huge" />)}</Table.Cell>
                              <Table.Cell textAlign="center" style={{borderLeft:'1px solid #d0d0d0'}}>{this.state.fetchingdata?(<Icon style={{margin:'0 auto',marginTop: '-10px'}} name="mail outline" size="large" />):(<center>{obj.organiser && <Button style={{padding:0,border:'none',boxShadow:'none',cursor:"pointer"}} onClick={this.handleAnalytics(obj)}  basic as="a" href={"mailto:"+obj.organiser+"?subject=Found%20on%20the%20Event%20Analyzer%20platform&body=I%20found%20your%20event%20on%20The%20Financial%20Revolutionist%27s%20Event%20Analyzer%20platform.%20I%27d%20like%20to%20learn%20more%20about%20your%20event."}><Icon style={{margin:'0 auto',marginTop: '-10px'}} name="mail outline" size="large" /></Button>}</center>)}</Table.Cell>
                              <Table.Cell  textAlign = "center">{this.checkLoading(<Icon onClick={this.downloadModeChange.bind(this,obj)} style={{margin:'0 auto',marginTop: '-10px',cursor:"pointer"}} name="calendar alternate outline" size="large" />,<Icon  style={{margin:'0 auto',marginTop: '-10px',cursor:"pointer"}} name="calendar alternate outline" size="large" />)}</Table.Cell>
                              {DEFAULTPLAN!="Free1" && <Modal  open={this.state.modalOpen && this.state.modalOpen==obj.id } onClose={this.handleClose} trigger={!fetchingdata?<Table.Cell onClick={this.openConfPopup(obj.id)} className="conf-name" width={4} style={{cursor:'pointer'}}>{(obj.name)}</Table.Cell>:<Table.Cell className="conf-name" width={4} style={{cursor:'pointer'}}><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>}>
                                  <Modal.Content>
                                  <SingleConferencePage events={true} data={obj} unsave={this.handleEvent}  />
                                  </Modal.Content>
                                </Modal>}
                                {DEFAULTPLAN=="Free1" && <Table.Cell width={3}>{this.checkLoading(obj.name)}</Table.Cell> }
                              <Table.Cell width={3}>{this.checkLoading(obj.date)}</Table.Cell>
                              <Table.Cell width={1} >{this.checkLoading(obj.city)}</Table.Cell>
                              <Table.Cell width={3}>{this.checkLoading(obj.tags)}</Table.Cell>
                              <Table.Cell width={2} textAlign="right">{this.checkLoading(<Fragment>{obj.companies}{obj.peers?(<span> | {obj.peers} {obj.peers==1?'Peer':'Peers'}</span>):(null)}{obj.companies>0 && <div className='tooltip-child'>{this.getTooltip(obj)}</div>}</Fragment>)}</Table.Cell>
                              <Table.Cell width={1}>{fetchingdata? <a style={{fontWeight:"bold"}} > Agenda</a>:(obj.agenda_link?<a href={obj.agenda_link} target="_blank" style={{fontWeight:"bold"}} onClick={this.sendAgendaData.bind(this,obj.id,obj.download)}> Agenda</a>:null) }</Table.Cell>
                              <Table.Cell width={1} textAlign = "center">{!fetchingdata?(obj.download?this.checkLoading(<a href={obj.download} style= {{pointerEvents:'initial'}} target="_blank"  onClick={this.downloadCsv.bind(this,"Brochure",[obj.id],obj.download)}><Icon link style={{margin:'0 auto',marginTop: '-10px',color:"#000"}} name="arrow alternate circle down outline" size="large" /></a>,""):""):<Icon link style={{margin:'0 auto',marginTop: '-10px'}} name="arrow alternate circle down outline" size="large" />}</Table.Cell>
                      </Table.Row>) 
                  }
                </Table.Body>
       </Table>}
       {this.state.conf_list.length<1 && <h5 style={{marginBottom:"170px"}}> There are no events that match this specific criteria. Please adjust the filters, or select "Reset" to return
       to the system defaults. <br/> You can also email our team to add any events of interest at <Button as= 'a'  style = {{backgroundColor:"transparent",color:"#5290ff",paddingLeft:"0"}} href='mailto:support@thefr.com?subject=Add new event to Event Analyzer'>support@thefr.com</Button></h5>}
       { (DEFAULTPLAN==="Free" || DEFAULTPLAN==="Month") && <div id="search-events-template"></div> }
       {this.state.downloading &&
                <Dimmer active page inverted>
                               <Loader inline='centered' inverted content='Loading' />
                            </Dimmer>
              }
    </Container>
    </Fragment>
    )
  }
}

export default withRouter(SearchEvents)