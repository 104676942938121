//Core file that initializes the react app
import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import BasicLayout from './views/components/BasicLayout.jsx';
import * as serviceWorker from './serviceWorker';
import './assets/css/semantic.min.css';
import axios from 'axios';
//React Router config
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

//Request handling module
const indexRoutes = [{ path: "/", component: BasicLayout }];

const hist = createBrowserHistory();

function checkIfPreview() {
  var url = window.location.href;
  if(url.indexOf("/preview/")!==-1){
    return true;
  }
  return false;
}

//Google analytics code
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-143519074-1'); 
// ReactGA.pageview(window.location.pathname)

//Basic config of axios
//console.log(process.env);
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.withCredentials = true;
// axios.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     //console.log(config,"ccc")
//     var params=qs.parse(window.location.search,{ignoreQueryPrefix:true});
//     if(params.campaign_id){
//       config.headers['x-camp']=params.campaign_id;
//     }
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

if(!checkIfPreview()) {
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    if(error.response){
    if(error.response.status==403){
      //console.log(error,localStorage.user,"eeeee")
      if(localStorage.user_details){
        localStorage.removeItem('user_details');
        hist.push('/')
        //toastr.error('Session Expired','Your session expired due to inactivity for a long period. Please login again to continue')
      }
      else{
        hist.push('/');
        //toastr.error('No Session','Please login  to continue')
      }
    }
    else{
       return Promise.reject(error);
    }
    }
    else{
       return Promise.reject(error);
    }
  
    // Do something with response error
   
  });
//Core layout of the app
if(!localStorage.getItem('user_details')){
  hist.push('/');
}
else{
  var email = JSON.parse(localStorage.getItem('user_details')).email;
  axios.post('/new_login',{email}).then(data=>{
    if(!data.data.first_login) {
      console.log("i22222qwjoder");
      localStorage.setItem('user_details',JSON.stringify(data.data));
      if(window.location.pathname=='/') {
         hist.push('/search-events');
      }
    }
    else {
      console.log('tgot');
         hist.push('/');
    }
  })
}

// hist.listen((location, action) => {
// var tp = window.tp;
// tp.experience.init();
// if(window.location.pathname!='/'){
//   if(tp.pianoId){
//   if(tp.pianoId.isUserValid()){
//         var user = tp.pianoId.getUser();
//         if(user){
//                 axios.get('session_check').then(data => {
//                     axios.post('get_access_info',{user_id:user.uid}).then(details => {
//                     if(details.data.accesses.length>0){
//                       //console.log()
//                       //localStorage.setItem('user_details',details.data)
//                        var user = data.data;
//                       if(details.data.accesses.length==1 && details.data.accesses[0].granted){
//                           data.data.access = details.data.accesses[0].resource.rid;
//                       //console.log(new Date(details.data.accesses[1].start_date*1000).toLocaleDateString("en-US"))
                     
//                       //console.log(user.access,"a")
//                                if(user.access == process.env.REACT_APP_ANN_PLAN){
//                                     user.plan = 'Annual';
//                                     //user.industries = process.env.REACT_APP_ANN_IND
//                                   }
//                                   else if(user.access == process.env.REACT_APP_MTM_PLAN){
//                                     user.plan = 'Month';
//                                     //user.industries = process.env.REACT_APP_MTM_IND
//                                   }
//                                   else  if(user.access == process.env.REACT_APP_FREE_PLAN){
//                                     user.plan = 'Free';
//                                     //user.industries = process.env.REACT_APP_FREE_IND
//                                   }
                                  
                                 
//                                   else{
//                                      if(window.location.href!='/') {
//                               hist.push('/')
//                              // window.location.href = 'https://thefr.com/event-analyzer';
//                             }
//                                    // window.location.href = 'https://thefr.com/event-analyzer';
//                                   }
//                       }
//                       else {
//                         var annual = details.data.accesses.filter(x => {
//                           if(x.resource.rid==process.env.REACT_APP_ANN_PLAN && x.granted){
//                             return x;
//                           }
//                         })
//                         if(annual.length>0){
//                           user.plan = 'Annual';
//                         }
//                         else{
//                           var month = details.data.accesses.filter(x => {
//                           if(x.resource.rid==process.env.REACT_APP_MTM_PLAN && x.granted){
//                             return x;
//                           }
//                            })
//                           if(month.length>0){
//                             user.plan = 'Month'
//                           }
//                           else{
//                              var free = details.data.accesses.filter(x => {
//                               if(x.resource.rid==process.env.REACT_APP_FREE_PLAN && x.granted){
//                                 return x;
//                               }
//                                })
//                            if(free.length>0){
//                             user.plan = 'Free'
//                            }
//                            else{
//                            if(window.location.href!='/') {
//                               hist.push('/')
//                              // window.location.href = 'https://thefr.com/event-analyzer';
//                             }
//                             //  window.location.href = 'https://thefr.com/event-analyzer';
//                            }
//                           }
//                         }
//                       }
//                       localStorage.setItem('user_details',JSON.stringify(user));
//                       //hist.push('/dashboard')
//                     }
//                     else{
//                        if(window.location.href!='/') {
//                               hist.push('/')
//                              // window.location.href = 'https://thefr.com/event-analyzer';
//                             }
//                      // window.location.href = "https://thefr.com/event-analyzer"
//                     }
//                   }).catch(err => {
//                     hist.push('/')
//                     console.log(err);
//                   }) 
//                 }).catch(err => {
//                   console.log(err);
//                    axios.post('login',{email:user.email,password:user.uid,firstname:user.given_name,lastname:user.family_name}).then(login => {
//                           if(login.status==200){
//                           axios.post('get_access_info',{user_id:user.uid}).then(details => {
//                             if(details.data.accesses.length>0){
//                               //console.log()
//                               login.data.access = details.data.accesses[0].resource.rid;
//                               var user = login.data;
//                               console.log(user.access,"a")
//                                         if(user.access == process.env.REACT_APP_ANN_PLAN){
//                                     user.plan = 'Annual';
//                                     //user.industries = process.env.REACT_APP_ANN_IND
//                                   }
//                                   else if(user.access == process.env.REACT_APP_MTM_PLAN){
//                                     user.plan = 'Month';
//                                     //user.industries = process.env.REACT_APP_MTM_IND
//                                   }
//                                   else  if(user.access == process.env.REACT_APP_FREE_PLAN){
//                                     user.plan = 'Free';
//                                     //user.industries = process.env.REACT_APP_FREE_IND
//                                   }
//                                           else{
//                                              if(window.location.href!='/') {
//                               hist.push('/')
//                              // window.location.href = 'https://thefr.com/event-analyzer';
//                             }
//                                             //window.location.href = 'https://thefr.com/event-analyzer';
//                                           }
                                          
//                               localStorage.setItem('user_details',JSON.stringify(user));
//                               //hist.push('/dashboard')
//                             }
//                             else{
//                                if(window.location.href!='/') {
//                               hist.push('/')
//                              // window.location.href = 'https://thefr.com/event-analyzer';
//                             }
//                             //  window.location.href = "https://thefr.com/event-analyzer"
//                             }f
//                           }).catch(err => {
//                             console.log(err);
//                           })   
//                           }  
//                     }).catch(err => {
//                       if(err.response && err.response.status==400 && err.response.data.msg){
//                         hist.push('/')
//                       }
//                       tp.pianoId.logout();
//                       // hist.push('/')
                      
//                     })
//                 })
                
//         }
       
//         //console.log(tp.pianoId.getUser(),"user")
//   }
//   else{
//    hist.push('/')
    
//   }
//   }
// }

//  //console.log(location,action,'fghjhgh')   
// })
}

ReactDOM.render(
<Router history={hist}>
	<Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
