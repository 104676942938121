import React, { Component, Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  Form,
  Checkbox,
  Message,
  Dimmer,
  Loader,
  Grid,
  Icon,
  Divider,
  Select,
  Dropdown
} from 'semantic-ui-react';
import { withRouter,Link } from "react-router-dom";
import banner from './../../assets/img/banner.png'
import brandLogo from './../../assets/img/brandlogo.png';
import './../../assets/css/main.css';
import axios from 'axios';
 import logo from './../../assets/img/banner.jpg';
 var DEFAULTPLAN='Annual';
 var OPTIONS=['Financial Services',
 'Fintech',
 'Academia',
 'Accounting',
 'Government',
 'Insurance',
 'Investment Banking',
 'Investor',
 'Legal',
 'Marketing/Public Relations',
 'Media/Conference Organizer',
 'Real Estate',
 'Recruiting',
 'Other'];
 var MSG_wrong_email = 'We could not find this email. Please register using the link below if you have not registered before.';
 var MSG_user_exist = 'This email is already registered. Please login to continue.';
 var  MSG_success = 'Registered successfully. Please login to continue';
 var c_focus_options=OPTIONS.map(o=>{return {key:o,value:o,text:o}});
 var CF_OPTIONS = ['United States','A European Union Country', 'Any Other Country'];

var c_residence_options=CF_OPTIONS.map(o=>{return {key:o,value:o,text:o}});
var DP_OPTIONS = [
 'CEO/Founder/President',
 'C-Level Executive',
 'Corporate Development/Strategy',
 'Innovation',
 'Sales/Business Development',
 'Marketing',
 'Investor/M&A',
 'Operations (Finance/HR/Legal)',
 'Product (UX/UI)',
 'Information Technology',
 'Other'
];
var department_options = DP_OPTIONS.map(o=>{return {key:o,value:o,text:o}});

class LoginPage extends Component{
	state = {active_page:'login',error:false,errormsg:false,email:'',r_error:false,r_errormsg:'',r_success:'',r_successmsg:'',
	password:'',loadingscreen:false,company_focus:'',country_of_residence:'',title:'',department:'',other_department:'',
    register_email:'',register_password:'',email_update:false,lg1_email_update:false,
    email_customize:false,lg1_email_customize:false,
    other_company_focus:'',lg1_other_company_focus:'',
	firstname:'',lastname:'',company:'',lgn1_firstname:'',lg1_lastname:'',lg1_company_name:'',lg1_title:'',lg1_department:'',lg1_other_department:''
	,lg1_company_focus:'',lg1_error:false,lg1_errormsg:'',lg1_country_of_residence:''};
	handleChange = name => event => {
		// this.setState({error:false});
		this.setState({[name]:event.target.value}) 
	}
    selectionChange=(event,{value})=>{
    	this.setState({lg1_company_focus:value})
    }
    company_focus_change=(event,{value})=>{
      this.setState({company_focus:value})
    }
   lg1_company_focus_change=(event,{value})=>{
      this.setState({lg1_company_focus:value})
    }
    c_residence_change=(event,{value})=>{
       this.setState({country_of_residence:value})
    }
    lg1_c_residence_change=(event,{value})=>{
       this.setState({lg1_country_of_residence:value})
    }
    department_change=(event,{value})=>{
       this.setState({department:value})
    }
    lg1_department_change=(event,{value})=>{
       this.setState({lg1_department:value})
    }
	componentDidMount(){
   //  if(localStorage.getItem('user_details')) {
   //  	var email = JSON.parse(localStorage.getItem('user_details')).email;
   //  	axios.post('/new_login',{email}).then((data)=>{
			// 	var detail = data.data;
			// 	detail.plan=DEFAULTPLAN;
			// 	localStorage.setItem('user_details',JSON.stringify(detail));
			// 	this.props.history.push('/search-events');
			// }).catch(err=>{
			// 	console.log(err);
			// 	localStorage.removeItem('user_details');
			// 	this.setState({loadingscreen:false})
			// });
   //  }
	
	
	}
	login=()=>{
		this.setState({loadingscreen:true,r_success:false},()=>{
			axios.post('/new_login',{email:this.state.email}).then((data)=>{
				var detail = data.data;
				detail.plan=DEFAULTPLAN;
				if(detail.first_login) {
					localStorage.setItem('user_details',JSON.stringify(detail));
				// this.props.history.push('/search-events');
				this.setState({loadingscreen:false,active_page:'login1',
					id:detail.id,
					lg1_lastname:detail.lastname?detail.lastname:null,
				    lgn1_firstname:detail.firstname,
				    lg1_company_name:detail.company_name,
				    lg1_department:detail.department,
				    lg1_company_focus:detail.company_focus,
				    lg1_title:detail.title,
				    lg1_country_of_residence:detail.country_of_residence
				});
				if(!OPTIONS.find(focus=>focus==detail.company_focus) ){
					this.setState({lg1_other_company_focus:detail.company,lg1_company_focus:'Other'})
				   }
			     if(!DP_OPTIONS.find(focus=>focus==detail.department) ){
				   this.setState({lg1_other_department:detail.department,lg1_department:'Other'})
			     }

				}
				else{
					data.data.plan=DEFAULTPLAN;
					localStorage.setItem('user_details',JSON.stringify(data.data))
					this.props.history.push('/search-events');
				}
			}).catch(err=>{
				if(err.response) {
					localStorage.removeItem('user_details');
					if(err.response.data.msg=='user do not exist'){
						this.setState({error:true,errormsg:MSG_wrong_email})
					}
					
				}
				this.setState({loadingscreen:false})
			});
		})
	}
	login1=()=>{
		var body={
				firstname:this.state.lgn1_firstname,
				lastname:this.state.lg1_lastname,
				company_name:this.state.lg1_company_name,
				title:this.state.lg1_title,
				department:this.state.lg1_department,
				company_focus:this.state.lg1_company_focus,
				country_of_residence:this.state.lg1_country_of_residence,
				first_login:false

			}
			var id = JSON.parse(localStorage.getItem('user_details')).id;
			body.id=id;
		const { lg1_country_of_residence,
				lg1_email_customize,
				lg1_email_update,
				lg1_other_company_focus,
				lg1_company_focus,
				lg1_department,
				lg1_other_department }=this.state;
				// console.log(lg1_country_of_residence,lg1_department);
			if(lg1_country_of_residence=='' || lg1_department=='' || lg1_company_focus=='') {
		    	return this.setState({lg1_error:true,lg1_errormsg:'All fields are required!'}) 
		    }
			if(lg1_country_of_residence=='A European Union Country' && (!lg1_email_customize && !lg1_email_update )) {
				console.log('got')
			      return this.setState({lg1_error:true,lg1_errormsg:'Please select at least one option for country European'})
		   }
		   if(lg1_department=='Other') {
				body.department=lg1_other_department;
			}
            if(lg1_country_of_residence=='A European Union Country') {
            	body.europe_custom_updates=lg1_email_customize;
            	body.europe_promo_updates=lg1_email_update;
            }
            if(lg1_company_focus=='Other') {
            	body.company_focus = lg1_other_company_focus;
            }
            // console.log(body);
		this.setState({loadingscreen:true},()=>{
			
			
			axios.post('/update_details',body).then(data=>{
				data.data.plan=DEFAULTPLAN;
				localStorage.setItem('user_details',JSON.stringify(data.data));
				this.props.history.push('/search-events');
			}).catch(err=>{
				console.log(err);
			})
		})
	}
	register=()=>{
		const { company,firstname,lastname,register_email, 
			country_of_residence, company_focus,other_company_focus,
		    email_customize, email_update,department, other_department,title} = this.state;
		    if(country_of_residence=='' || department=='' || company_focus=='') {
		    	return this.setState({r_error:true,r_errormsg:'All fields are required!'}) 
		    }
			if(country_of_residence=='A European Union Country' && (!email_customize && !email_update )) {
			      return this.setState({r_error:true,r_errormsg:'Please select at least one option for country European'})
		   }
		this.setState({loadingscreen:true},()=>{
			var body={firstname,lastname,
				email:register_email,company,company_focus,title,
				country_of_residence,first_login:false,department};
			if(department=='Other') {
				body.department=other_department;
			}
            if(country_of_residence=='A European Union Country') {
            	body.europe_custom_updates=email_customize;
            	body.europe_promo_updates=email_update;
            }
            if(company_focus=='Other') {
            	body.company_focus = other_company_focus;
            }
            console.log(body);
			axios.post('/register',body).then((data)=>{			
			// this.setState({loadingscreen:false,r_error:false,r_success:true, r_successmsg:MSG_success,active_page:'login',
			// 	firstname:'',
			// 	lastname:'',
			// 	company:'',
			// 	register_email:'',
			// 	email:'',
			// 	company_focus:'',
			// 	other_company_focus:'',
			// 	department:'',
			// 	other_department:'',
			// 	''				
			// });
            axios.post('/new_login',{email:this.state.register_email}).then(data=>{
            	var details=data.data;
            	details.plan=DEFAULTPLAN;
			localStorage.setItem('user_details',JSON.stringify(data.data));            	
            	this.props.history.push('/search-events');
            }).catch(err=>{
            	console.log(err);
            })
		}).catch(err=>{
			if(err.response) {
				console.log(err.response);					
					this.setState({r_error:true,r_errormsg:MSG_user_exist,r_success:false})
				}
               this.setState({loadingscreen:false})
		  })
		})
	}
	render(){
		const {email,password,register_email,register_password,
			company,firstname,lastname,lgn1_firstname,lg1_lastname,lg1_company_name,
			lg1_company_focus,lg1_title,lg1_department,
			country_of_residence,lg1_country_of_residence,
			company_focus,other_company_focus,lg1_other_company_focus,
			title,department,other_department,
			email_update,lg1_email_update,email_customize,lg1_email_customize,lg1_other_department
		} = this.state;
		//console.log(this.state,"state")
		return(
			<div className="login-block">
			<style>
				
				{ `.login-box{ padding:10px !important}
				   .checkbox label {
					    font-size: 12px !important;
					}
                ` }
			</style>
			
			{/*<Image className="login-banner" fluid src={banner} />*/}
			<Container fluid >
			<Image centered src={logo} /><br/>
			<Header as="h1" textAlign="center">A Comprehensive Financial Services & Fintech Events Platform</Header>
			<h4 style={{width:'1024px',textAlign:'center',margin:'0 auto'}}>As you navigate all the changes to events due to the Coronavirus, we're providing the fintech and financial services community with a complimentary, all-access pass to Event Analyzer.</h4>
			<h4 style={{width:'1024px',textAlign:'center',margin:'0 auto',fontWeight:'normal'}}>Stay up to date on conference date changes, and start to plan ahead for the third and fourth quarters.</h4>
			<Grid  style={{margin: '20px 0'}} centered>

			<Grid.Column computer={this.state.access_expired?5:4} largeScreen={this.state.access_expired?16:5} mobile={16} tablet={16} >
			<Segment className="login-box" raised style={{display:this.state.access_expired?'flex':'block'}}>
			{ this.state.r_success &&
				     <Message>{this.state.r_successmsg}</Message> }
				{this.state.loadingscreen && <div style={{minHeight: '210px'}}>
				 <Dimmer active inverted>
			        <Loader size='large'>Loading</Loader>
			      </Dimmer>
			       
			      </div>}
				
				
				
				{(this.state.active_page==='login' && !this.state.loadingscreen) && <Form onSubmit={this.login}>
				 <Message
				 		error
				 		visible={this.state.error}
				 		onDismiss={this.closeError}
					    content={this.state.errormsg}
					  />
					  
				    <Form.Field>
				      <label>E-Mail</label>
				      <input value={email} onChange={this.handleChange('email')} name="email" placeholder='E-Mail' required/>
				    </Form.Field>
				    
				    
				    <center>
				    <Button actionLogin loading={this.state.loading} disabled={this.state.loading} fluid primary type='submit' size="large" >NEXT</Button></center>
				   
				    
				    </Form>}
                    { (this.state.active_page==='register' && !this.state.loadingscreen) && <Form onSubmit={this.register}>
				    
					  { this.state.r_error &&
				     <Message
				 		onDismiss={this.closeError}
					    error
					    visible={this.state.r_error}
					    content={this.state.r_errormsg}
					  /> }
					  <br/>
					<Form.Field>
				      <label>First name</label>
				      <input value={firstname} onChange={this.handleChange('firstname')} name="firstname" placeholder='First name' required/>
				    </Form.Field>  
					<Form.Field>
				      <label>Last name</label>
				      <input value={lastname} onChange={this.handleChange('lastname')} name="lastname" placeholder='Last name' />
				    </Form.Field>  
					<Form.Field>
				      <label>E-Mail</label>
				      <input value={register_email} onChange={this.handleChange('register_email')} name="email" placeholder='E-Mail' required/>
				    </Form.Field>  
				    
				    <Form.Field>
				      <label>Company</label>
				      <input value={company} onChange={this.handleChange('company')} name="company" placeholder='Company' required/>
				    </Form.Field>
				    <Form.Field>
				      <label>Title</label>
				      <input value={title} onChange={this.handleChange('title')} name="lg1_title" placeholder='Title' required/>
				    </Form.Field>
				    <Form.Field>
				      <label>Department</label>
				      <Dropdown value={department} selection options={department_options}  onChange={this.department_change} name="department" placeholder='Select department' required/>
				    </Form.Field>
				    {department=='Other' &&<Form.Field>
				      <input placeholder='Other department' value ={ other_department } onChange={this.handleChange('other_department')}  required/>
				    </Form.Field>}
				    <Form.Field>
				      <label>Company focus</label>
				      <Dropdown placeholder='Company focus' value ={ company_focus }selection options={c_focus_options} onChange={this.company_focus_change} required/>
				    </Form.Field>
				    {company_focus=='Other' &&<Form.Field>
				      <input placeholder='Other company focus' value ={ other_company_focus } onChange={this.handleChange('other_company_focus')}  required/>
				    </Form.Field>}
				    <Form.Field>
				      <label>Country of residence </label>
				      <Dropdown placeholder='Country of residence' value ={ country_of_residence }selection options={c_residence_options} onChange={this.c_residence_change} required/>
				    </Form.Field>
				    {country_of_residence=='A European Union Country' &&<Form.Field>
				       <Checkbox style={{marginBottom:"15px"}} label='EMAIL ME CUSTOMIZED UPDATES BASED ON THE INTEREST & EVENTS I SELECT IN MY ACCOUNT' checked={ email_customize} onClick={()=>this.setState({email_customize:!email_customize})}/>
				       <br/>
				       <Checkbox label='EMAIL ME UPDATES & PROMOTIONS FROM THE FINANCIAL REVOLUTIONIST' checked={ email_update} onClick={()=>this.setState({email_update:!email_update})}/>
				    </Form.Field>}
				    <center>
				    <Button actionLogin loading={this.state.loading} disabled={this.state.loading} fluid primary type='submit' size="large" >SIGN UP</Button></center>
				    
				    
				    </Form>}

				    { (this.state.active_page==='login1' && !this.state.loadingscreen) && <Form onSubmit={this.login1}>
				     
					  { this.state.lg1_error &&
				     <Message
				 		onDismiss={this.closeError}
					    error
					    visible={this.state.lg1_error}
					    content={this.state.lg1_errormsg}
					  /> }
					  <br/>
					<Form.Field>
				      <label>First name</label>
				      <input value={lgn1_firstname} onChange={this.handleChange('lgn1_firstname')} name="lgn1_firstname" placeholder='First name' required/>
				    </Form.Field>  
					<Form.Field>
				      <label>Last name</label>
				      <input value={lg1_lastname} onChange={this.handleChange('lg1_lastname')} name="lg1_lastname" placeholder='Last name' required/>
				    </Form.Field>  
					<Form.Field>
				      <label>Company</label>
				      <input value={lg1_company_name} onChange={this.handleChange('lg1_company_name')} name="lg1_company" placeholder='Company' required/>
				    </Form.Field>  
				    
				    <Form.Field>
				      <label>Title</label>
				      <input value={lg1_title} onChange={this.handleChange('lg1_title')} name="lg1_title" placeholder='Title' required/>
				    </Form.Field>
				    <Form.Field>
				      <label>Department</label>
				      <Dropdown value={lg1_department}  selection options={department_options} onChange={this.lg1_department_change}name="lg1_department" placeholder='Department' required/>
				    </Form.Field>
				    {lg1_department=='Other' &&<Form.Field>
				      <input placeholder='Other department' value ={ lg1_other_department } onChange={this.handleChange('lg1_other_department')}  required/>
				    </Form.Field>}
				    <Form.Field>
				      <label>Company focus</label>
				      <Dropdown placeholder='Company focus' value ={ lg1_company_focus }selection options={c_focus_options} onChange={this.selectionChange} required/>
				    </Form.Field>
				    {lg1_company_focus=='Other' &&<Form.Field>
				      <input placeholder='Other company focus' value ={ lg1_other_company_focus } onChange={this.handleChange('lg1_other_company_focus')}  required/>
				    </Form.Field>}
				    <Form.Field>
				      <label>Country of residence </label>
				      <Dropdown placeholder='Company focus' value ={ lg1_country_of_residence }selection options={c_residence_options} onChange={this.lg1_c_residence_change} required/>
				    </Form.Field>
				    {lg1_country_of_residence=='A European Union Country' &&<Form.Field>
				       <Checkbox style={{marginBottom:"15px"}} label='EMAIL ME CUSTOMIZED UPDATES BASED ON THE INTEREST & EVENTS I SELECT IN MY ACCOUNT' checked={ lg1_email_customize} onClick={()=>this.setState({lg1_email_customize:!lg1_email_customize})}/>
				       <br/>
				       <Checkbox label='EMAIL ME UPDATES & PROMOTIONS FROM THE FINANCIAL REVOLUTIONIST' checked={ lg1_email_update} onClick={()=>this.setState({lg1_email_update:!lg1_email_update})}/>
				    </Form.Field>}
				    
				    <center>
				    <Button actionLogin loading={this.state.loading} disabled={this.state.loading} fluid primary type='submit' size="large" >SUBMIT</Button></center>
				    
				    
				    </Form>}
				
				</Segment>
				<br/>
				
				{ this.state.active_page=='login' && <button  style={{fontWeight:'bold',cursor:'pointer',border:'none',outline:'none',color:'#5290ff',float:'right',background:'none',fontFamily:'inherit'}} onClick={()=>this.setState({active_page:'register',r_error:false,error:false})}>New User? Register Here</button>}
				{this.state.active_page=='register'&& <button  style={{fontWeight:'bold',cursor:'pointer',border:'none',outline:'none',color:'#5290ff',float:'right',background:'none',fontFamily:'inherit'}} onClick={()=>this.setState({active_page:'login',r_error:false,error:false})}>Already registered? Login Here</button> }	

			</Grid.Column>
			
			</Grid>
			{ (this.state.active_page=='login1' || this.state.active_page=='register') && <h4 style={{textAlign:"center"}}>
					By {this.state.active_page=='login1'?'clicking':null} { this.state.active_page=='login1'?' submit':'signing up' }, you agree to the <a href="https://thefr.com/terms" target="_blank">Terms of service </a> and 
					<a href="https://thefr.com/privacy-policy" target="_blank"> Privacy Policy</a>
				</h4>}

			</Container>

			</div>
		)
	}
}

export default withRouter(LoginPage);

